import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";

import cardSectionVideo1 from "../../animations/v3/ai/animations/cardSectionVideo1.json";
import cardSectionVideo2 from "../../animations/v3/ai/animations/cardSectionVideo2.json";
import cardSectionVideo3 from "../../animations/v3/ai/animations/cardSectionVideo3.json";
import cardSectionVideo4 from "../../animations/v3/ai/animations/cardSectionVideo4.json";
import cardSectionVideo5 from "../../animations/v3/ai/animations/cardSectionVideo5.json";

import aiPageHeroSection from "../../animations/v3/ai/aiPageHeroSection.mp4";

import aiAnimationJson1 from "../../animations/v3/ai/animations/aiAnimationJson1.json";
import aiAnimationJson2 from "../../animations/v3/ai/animations/aiAnimationJson2.json";
import aiAnimationJson3 from "../../animations/v3/ai/animations/aiAnimationJson3.json";
import aiAnimationJson4 from "../../animations/v3/ai/animations/aiAnimationJson4.json";
import aiAnimationJson5 from "../../animations/v3/ai/animations/aiAnimationJson5.json";

import bentoIcon1 from "../../images/v4/aiPage/icons/bentoIcon1.svg";
import bentoIcon2 from "../../images/v4/aiPage/icons/bentoIcon2.svg";
import bentoIcon3 from "../../images/v4/aiPage/icons/bentoIcon3.svg";
import bentoIcon4 from "../../images/v4/aiPage/icons/bentoIcon4.svg";
import bentoIcon5 from "../../images/v4/aiPage/icons/bentoIcon5.svg";
import bentoIcon6 from "../../images/v4/aiPage/icons/bentoIcon6.svg";
import bentoIcon7 from "../../images/v4/aiPage/icons/bentoIcon7.svg";
import bentoIcon8 from "../../images/v4/aiPage/icons/bentoIcon8.svg";
import bentoIcon9 from "../../images/v4/aiPage/icons/bentoIcon9.svg";
import bentoIcon10 from "../../images/v4/aiPage/icons/bentoIcon10.svg";
import bentoIcon11 from "../../images/v4/aiPage/icons/bentoIcon11.svg";
import bentoIcon12 from "../../images/v4/aiPage/icons/bentoIcon12.svg";
import bentoIcon13 from "../../images/v4/aiPage/icons/bentoIcon13.svg";
import bentoIcon14 from "../../images/v4/aiPage/icons/bentoIcon14.svg";
import bentoIcon15 from "../../images/v4/aiPage/icons/bentoIcon15.svg";

import partnerIcon1 from "../../images/v4/aiPage/icons/partnerIcon1.png";
import partnerIcon2 from "../../images/v4/aiPage/icons/partnerIcon2.png";
import partnerIcon3 from "../../images/v4/aiPage/icons/partnerIcon3.png";
import partnerIcon5 from "../../images/v4/aiPage/icons/partnerIcon5.png";
import partnerIcon6 from "../../images/v4/aiPage/icons/partnerIcon6.png";
import partnerIcon7 from "../../images/v4/aiPage/icons/partnerIcon7.png";
import partnerIcon8 from "../../images/v4/aiPage/icons/partnerIcon8.png";

import excellenceIcon1 from "../../images/v4/aiPage/icons/excellenceIcon1.svg";
import excellenceIcon2 from "../../images/v4/aiPage/icons/excellenceIcon2.svg";
import excellenceIcon3 from "../../images/v4/aiPage/icons/excellenceIcon3.svg";
import excellenceIcon4 from "../../images/v4/aiPage/icons/excellenceIcon4.svg";
import excellenceIcon5 from "../../images/v4/aiPage/icons/excellenceIcon5.svg";
import excellenceIcon6 from "../../images/v4/aiPage/icons/excellenceIcon6.svg";
import excellenceIcon7 from "../../images/v4/aiPage/icons/excellenceIcon7.svg";
import excellenceIcon8 from "../../images/v4/aiPage/icons/excellenceIcon8.svg";
import excellenceIcon9 from "../../images/v4/aiPage/icons/excellenceIcon9.svg";
import excellenceIcon10 from "../../images/v4/aiPage/icons/excellenceIcon10.svg";
import excellenceIcon11 from "../../images/v4/aiPage/icons/excellenceIcon11.svg";
import excellenceIcon12 from "../../images/v4/aiPage/icons/excellenceIcon12.svg";

import whatsappSection from "../../animations/v3/homepage/json/whatsappSection.json";

import absoluteImage from "../../images/v4/aiPage/icons/absoluteImage.svg";
import aiIcon from "../../images/v4/aiPage/icons/aiIcon.svg";
import glareIcon from "../../images/v4/aiPage/icons/glareIcon.svg";
// import sparkIcon from "../../images/v4/aiPage/icons/sparkIcon.svg";
// import aiCardImage from "../../images/v4/aiPage/images/aiCardImage1.png";
import FaqSection from "../../components/v4/FaqSection/FaqSection";
import pageBackground from "../../images/v4/aiPage/images/pageBackground.webp";
import aiPageBackground2 from "../../images/v4/aiPage/images/aiPageBackground2.webp";
import benifitSectionBackground from "../../images/v4/aiPage/images/benifitSectionBackground.webp";
// import VideoComponent from "../../components/VideoComponent/VideoComponent";
import LazyImage from "../../components/v4/LazyLoadImage/LazyImage";
import PlayableVideoComponent from "../../components/VideoComponent/PlayableVideoComponent";

import SEO from "../../components/SEO";
import Link from "../../components/v2/Link";
import LottieIcon from "../../components/v4/LottieIcon";

const useStyles = makeStyles((theme) => ({
  pageBackground: {
    backgroundSize: "cover",
    backgroundImage: `url(${pageBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  innerHeroSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    margin: "auto",
    textAlign: "center",
    "& h1": {
      background:
        "linear-gradient(88deg, #98D9FF 0%, #99E0FA 50%, #9EFFE5 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "& p": {
      color: "#B5D2E8",
      width: "80%",
    },
    "& .buttonContaienr": {
      justifyContent: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& img": {
        width: "50px",
      },
      "& p": {
        width: "100%",
      },
      "& .buttonContaienr": {
        flexDirection: "row",
        justifyContent: "center",
      },
    },
  },
  cardsSection: {
    "& .centerHeader": {
      gap: "10px",
    },
    "& .gradientHeading": {
      background:
        "linear-gradient(89deg, #98D9FF 0%, #99E0FA 50%, #9EFFE5 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      "& img": {
        height: "50px",
      },
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerHeader": {
        textAlign: "left",
        "& h2 img": {
          width: "30px",
          height: "30px",
        },
      },
    },
  },
  growthSection: {
    gap: "20px",
    width: "60%",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
    "& .gradientHeading": {
      background:
        "linear-gradient(89deg, #98D9FF 0%, #99E0FA 50%, #9EFFE5 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  cardContainer: {
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "18px",
    padding: "25px",
    background: "rgba(0, 44, 81, 0.4)",
    backdropFilter: "blur(20px)",
    height: "520px",
    position: "relative",
    overflow: "hidden",
    "&.purpleCard": {
      background: "#41086E",
      "&.glass-effect": {
        "&::before": {
          content: '""',
          position: "absolute",
          width: "400px",
          height: "400px",
          zIndex: "-1",
          background: "radial-gradient(circle, #FF8958 0%, #B458FF 100%)",
          borderRadius: "50%",
          filter: "blur(200px)",
          opacity: "var(--before-opacity, 0)",
          left: "var(--before-left, 0)",
          top: "var(--before-top, 0)",
          transition: "opacity 0.3s ease",
          pointerEvents: "none",
        },
      },
    },
    "&.blueCard": {
      background: "rgba(0, 154, 153, 0.4)",
      "&.glass-effect": {
        "&::before": {
          content: '""',
          position: "absolute",
          width: "250px",
          height: "250px",
          zIndex: "-1",
          background: "radial-gradient(circle, #1ADAD9 30%, #00aa7c 50%)",
          borderRadius: "50%",
          filter: "blur(100px)",
          opacity: "var(--before-opacity, 0)",
          left: "var(--before-left, 0)",
          top: "var(--before-top, 0)",
          transition: "opacity 0.3s ease",
          pointerEvents: "none",
        },
      },
    },
    "&.regularCard": {
      background: "rgba(0, 44, 81, 0.4)",
      "&.glass-effect": {
        "&::before": {
          content: '""',
          position: "absolute",
          width: "250px",
          height: "250px",
          zIndex: "-1",
          background: "radial-gradient(circle, #3ac4ff 30%, #1f79d3 50%)",
          borderRadius: "50%",
          filter: "blur(100px)",
          opacity: "var(--before-opacity, 0)",
          left: "var(--before-left, 0)",
          top: "var(--before-top, 0)",
          transition: "opacity 0.3s ease",
          pointerEvents: "none",
        },
      },
    },
  },
  bigCardContainer: {
    marginTop: "80px",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "& $cardContainer": {
      width: "49%",
    },
    "@media only screen and (max-width: 600px)": {
      marginTop: "40px",
      gap: "15px",
      flexDirection: "column",
      "& $cardContainer": {
        height: "350px",
        width: "100%",
        padding: "16px",
      },
    },
  },
  smallCardContainer: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "& $cardContainer": {
      width: "32%",
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      marginTop: "16px",
      gap: "15px",
      "& $cardContainer": {
        height: "400px",
        width: "100%",
        padding: "16px",
      },
    },
  },
  benifitSection: {
    backgroundSize: "cover",
    borderRadius: "0 0 20px 20px",
    backgroundImage: `url(${benifitSectionBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    "& .container": {
      paddingBottom: "160px",
    },
  },
  integrationHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "30px",
    },
  },
  absoluteCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    background: "linear-gradient(94deg, #009A99 0%, #0060A8 100%)",
    padding: "50px 40px",
    borderRadius: "20px",
    overflow: "hidden",
    position: "absolute",
    width: "100%",
    "& .leftSection": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: "60%",
      gap: "20px",
    },
    "& .rightSection": {
      width: "35%",
      "& img": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      width: "90%",
      padding: "16px",
      "& .leftSection": {
        width: "100%",
      },
      "& .rightSection": {
        width: "100%",
      },
    },
  },
  integrationLeft: {
    width: "50%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  integrationRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "40%",
    gap: "20px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  excellenceSystem: {
    marginTop: "40px",
    marginBottom: "160px",
    gap: "30px",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 600px)": {
      marginBottom: "60px",
    },
  },
  excellenceSystemTabs: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: "20px",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 600px)": {
      overflow: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  excellenceSystemTab: {
    padding: "10px 0",
    cursor: "pointer",
    color: "white",
    transition: "0.3s ease",
    borderBottom: "1px solid transparent",
    "&.active": {
      color: "white",
      borderColor: "#FF7056",
    },
    "@media only screen and (max-width: 600px)": {
      minWidth: "max-content",
    },
  },
  excellenceSystemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    gap: "20px",
  },
  excellenceSystemCards: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      overflow: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  excellenceSystemCard: {
    display: "flex",
    minHeight: "370px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "25%",
    padding: "20px",
    borderRadius: "10px",
    gap: "16px",
    position: "relative",
    zIndex: "1",
    background: "#9E5AD5",
    transition: "0.3s ease",
    boxShadow: "0px 0px 20px 6px rgba(0, 0, 0, 0.2)",
    "& p": {
      color: "#FFFFFFB3",
    },
    "& .imageContainer": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    "&:not(:first-child)": {
      paddingLeft: "40px",
    },
    "&:nth-child(1)": {
      zIndex: "4",
    },
    "&:nth-child(2)": {
      background: "#7335A5",
      left: "-20px",
      zIndex: "3",
      "&:hover": {
        left: 0,
      },
    },
    "&:nth-child(3)": {
      background: "#632099",
      left: "-40px",
      zIndex: "2",
      "&:hover": {
        left: "-20px",
      },
    },
    "&:nth-child(4)": {
      background: "#4C0D7E",
      left: "-60px",
      zIndex: "1",
      "&:hover": {
        left: "-50px",
      },
    },
    "& .heading": {},
    "@media only screen and (max-width: 600px)": {
      minWidth: "75%",
      pointerEvents: "none",
      "&:nth-child(4)": {
        left: "-50px",
      },
    },
  },
  commonPageFooterSection: {
    background:
      "transparent linear-gradient(90deg, #011D32 0%, #00182B 100%) 0% 0% no-repeat padding-box",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justufyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  partnerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "50px",
    "& h2": {
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      gap: "20px",
    },
  },
  partnerIcons: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& img": {
      width: "12%",
    },
    "@media only screen and (max-width: 600px)": {
      justifyContent: "flex-start",
      overflow: "auto",
      gap: "20px",
      "& span": {
        minWidth: "40%",
      },
      "& img": {
        width: "120px",
      },
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  absoluteImage: {
    width: "100%",
    position: "absolute",
    bottom: "0",
    left: "0",
    display: "flex",
  },
  heroSectionOuter: {
    paddingTop: "110px",
    backgroundSize: "cover",
    backgroundImage: `url(${aiPageBackground2})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "unset",
    overflow: "hidden",
    position: "relative",
    "@media only screen and (max-width: 600px)": {
      paddingTop: "60px",
    },
  },
  homeSectionVideo: {
    borderRadius: "30px",
    marginTop: "90px",
    overflow: "hidden",
    "@media only screen and (max-width: 600px)": {
      borderRadius: "16px",
      marginTop: "60px",
    },
  },
}));

const Ai = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const faqs = [
    {
      question: "What is AI CRM?",
      answer:
        "AI CRM combines traditional customer relationship management software with artificial intelligence capabilities to automate tasks, provide insights, and enhance customer interactions.",
    },
    {
      question: "How does AI improve CRM functionality?",
      answer:
        "AI enhances CRM by automating routine tasks, providing predictive analytics, offering personalised recommendations, and improving customer support through chatbots and intelligent assistants.",
    },
    {
      question: "How does AI CRM enhance customer support?",
      answer:
        "AI CRM can provide 24/7 automated chat support, answer FAQs instantly, and seamlessly hand off complex queries to human agents when necessary.",
    },
    {
      question: "What channels can AssistAI chatbot support?",
      answer:
        "AssistAI chatbot can automate and personalise interactions across WhatsApp, Telegram, Facebook, and Instagram.",
    },
    {
      question: "How does AI CRM assist in task automation?",
      answer:
        "AI CRM can automate repetitive tasks like creating and updating records, managing notes and call logs, and searching for records using simple commands in the AssistAI panel.",
    },
    {
      question: "Can AI CRM help with sales forecasting?",
      answer:
        "Yes, AI CRM uses predictive analytics to forecast trends and outcomes, helping businesses make informed decisions and strategise effectively.",
    },
    {
      question: "How does AI CRM personalise customer interactions?",
      answer:
        "AI-powered insights enable tailored communication strategies, allowing businesses to adjust tone, rephrase messages, and deliver personalised content to each customer segment.",
    },
    {
      question: "Is AI CRM suitable for small and medium-sized businesses?",
      answer:
        "Yes, many AI CRMs, including Pepper Cloud, offer scalable solutions that can adapt to the evolving needs of small and medium-sized businesses.",
    },
    {
      question: "How to choose the best AI CRM software for your SMB?",
      answer:
        "To choose the best AI CRM, clearly define your goals and assess how the system's AI capabilities align with your business needs. Prioritise user-friendliness, integration potential, scalability, and available support to ensure successful implementation and long-term value for your growing business.",
    },
    {
      question: "What are the best 5 AI CRM?",
      answer: (
        <>
          Pepper Cloud AI CRM stands out with its advanced AI technology,
          AssistAI. Featuring sales conversation summarisation, intelligent
          communication enhancement, a conversational chatbot, 1-click customer
          responses, a dynamic knowledge base, and more, it is a top choice for
          growing businesses. Plus, its advanced sales features like auto-lead
          capture, sales forecasting, workflow automation, WhatsApp integration,
          voice and video calling, analytics, and more, make it a powerful
          AI-powered sales CRM.
          <br />
          <br />
          Other top AI CRMs include HubSpot (known for content generation and
          lead scoring), Salesforce (offering Einstein AI for sales
          predictions), Pipedrive (focused on pipeline management), and Zoho CRM
          (for insights and automation).
        </>
      ),
    },
  ];

  const capabilities = [
    {
      heading: "Easy task management with AssistAI",
      desc: "Efficiently manage your workflow with AssistAI by creating records, tasks, and more in just a click. Empower your team to automate routine tasks, reduce manual work, and drive sales growth.",
      points: [
        {
          icon: bentoIcon1,
          text: "Easily add calls, logs, events, and more.",
        },
        {
          icon: bentoIcon2,
          text: "Create and manage records with one click.",
        },
        {
          icon: bentoIcon3,
          text: "Assign records to agents with a single prompt.",
        },
      ],
      video: cardSectionVideo1,
      background: "linear-gradient(180deg, #00B69E 0%, #0131A1 100%)",
    },
    {
      heading: "Enhance communication with AssistAI",
      desc: "Leverage AssistAI to send context-oriented, highly relevant, and personalised responses to your customers. Build stronger customer relationships with AssistAI.",
      points: [
        {
          icon: bentoIcon4,
          text: "Instantly summarise lengthy chats.",
        },
        {
          icon: bentoIcon5,
          text: "Rephrase messages for clarity and impact.",
        },
        {
          icon: bentoIcon6,
          text: "Adjust tone for better communication.",
        },
      ],
      video: cardSectionVideo2,
      background: "linear-gradient(180deg, #8E32C3 0%, #660DAE 100%)",
    },
    {
      heading: "Train AssistAI with knowledge base",
      desc: "Turn your brochure, user guides, website, and other resources into a knowledge base for AssistAI. Train AssistAI to provide instant and accurate answers to every query with ease.",
      points: [
        {
          icon: bentoIcon7,
          text: "A central repository of knowledge for AssistAI.",
        },
        {
          icon: bentoIcon8,
          text: "Create multiple knowledge groups easily.",
        },
        {
          icon: bentoIcon9,
          text: "Add resources in various formats including text, URLs, and PDFs.",
        },
      ],
      video: cardSectionVideo3,
      background: "linear-gradient(180deg, #2484C5 0%, #7200C9 100%)",
    },
    {
      heading: "Sell and support 24/7 with AssistAI chatbot",
      desc: "Handle customer queries, resolve issues, and automate conversations anytime with the AssistAI chatbot. Launch the no-code chatbot across multiple channels to drive instant sales.",
      points: [
        {
          icon: bentoIcon10,
          text: "Enable round-the-clock customer support.",
        },
        {
          icon: bentoIcon11,
          text: "Flexible chat flows and customisable topics.",
        },
        {
          icon: bentoIcon12,
          text: "Smart handoff to human agents when needed.",
        },
      ],
      video: cardSectionVideo4,
      background: "linear-gradient(180deg, #00B181 0%, #1448C4 100%)",
    },
    {
      heading: "Send 1-click-responses with AssistAI",
      desc: "Empower your teams to reply within seconds with the power of AssistAI, a generative AI. Deliver seamless omnichannel customer experiences across popular platforms.",
      points: [
        {
          icon: bentoIcon13,
          text: "Automate replies for common questions.",
        },
        {
          icon: bentoIcon14,
          text: "Provide natural conversational flow.",
        },
        {
          icon: bentoIcon15,
          text: "Handle high-volume enquiries with ease.",
        },
      ],
      video: cardSectionVideo5,
      background: "linear-gradient(180deg, #8E32C3 0%, #660DAE 100%)",
    },
  ];

  const excellencePoints = [
    {
      heading:
        "Turn your CRM into a marketing powerhouse with AI and supercharge your growth.",
      cards: [
        {
          icon: excellenceIcon1,
          title: "Data-driven smart campaigns",
          desc: "Use AI-generated insights to uncover hidden trends and pain points. Create and launch impactful marketing campaigns that resonate with your target audience.",
        },
        {
          icon: excellenceIcon2,
          title: "Personalisation on autopilot",
          desc: "Craft tailored messages for each customer segment using AI.  Boost engagement automatically across every channel while you focus on strategy.",
        },
        {
          icon: excellenceIcon3,
          title: "Intelligent lead scoring",
          desc: "Utilise AI to score and segment leads effectively. Easily enable automated nurturing to guide the prospects through your funnel with perfectly timed, personalised touchpoints.",
        },
        {
          icon: excellenceIcon4,
          title: "Predictive analytics",
          desc: "Leverage AI to forecast customer behaviour and trends, enabling marketers to identify potential leads and optimise campaigns for better targeting.",
        },
      ],
    },
    {
      heading:
        "Supercharge your sales team with AI and turn every lead into a customer.",
      cards: [
        {
          icon: excellenceIcon5,
          title: "Auto-capture leads",
          desc: "Leverage AI to automatically capture leads from multiple channels. Effortlessly prioritise high-quality prospects to drive more wins.",
        },
        {
          icon: excellenceIcon6,
          title: "Smart follow-up automation",
          desc: "Automatically schedule follow-ups, send emails, and coordinate meetings based on your engagement history. Transform hours of manual work into minutes and close deals quickly.",
        },
        {
          icon: excellenceIcon7,
          title: "Tailored customer interactions",
          desc: "Analyse customer sentiment and customise communication to each customer and audience segment. Boost rapport, engagement, and conversion rates with generative AI.",
        },
        {
          icon: excellenceIcon8,
          title: "Build comprehensive profiles",
          desc: "Auto-extract customer details from their website and save your sales team hours of research. Streamline data collection and build comprehensive customer profiles with AI.",
        },
      ],
    },
    {
      heading:
        "Empower your support team with an AI-powered CRM to manage enquiries efficiently.",
      cards: [
        {
          icon: excellenceIcon9,
          title: "Smart chat summarisation",
          desc: "Automatically generate summaries of customer interactions, allowing managers to quickly assess the quality of support and identify areas for improvement.",
        },
        {
          icon: excellenceIcon10,
          title: "AI-enhanced communication",
          desc: "Leverage AssistAI to rephrase and adjust the tone of responses, ensuring consistent and appropriate communication across all customer touchpoints.",
        },
        {
          icon: excellenceIcon11,
          title: "Smart FAQ chatbot",
          desc: "Deploy an AI-powered chatbot that draws from your CRM knowledge base to provide instant, accurate answers to frequently asked questions, reducing wait times.",
        },
        {
          icon: excellenceIcon12,
          title: "Seamless escalation management",
          desc: "Use AI to intelligently route complex issues to the most qualified agents, ensuring efficient problem resolution and improving overall customer satisfaction.",
        },
      ],
    },
  ];

  useEffect(() => {
    const cards = document.querySelectorAll(".card");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const card = entry.target;
          const currentIndex = Array.from(cards).indexOf(card);

          if (entry.isIntersecting) {
            // Remove all previous classes first
            cards.forEach((c) => {
              c.classList.remove(
                "is-previous-1",
                "is-previous-2",
                "is-previous-3",
                "is-previous-4"
              );
            });

            // Add appropriate classes based on distance from current card
            cards.forEach((c, index) => {
              if (index < currentIndex) {
                const difference = currentIndex - index;
                if (difference <= 4) {
                  // Only apply effects to 4 cards back
                  c.classList.add(`is-previous-${difference}`);
                }
              }
            });
          }
        });
      },
      {
        root: null,
        threshold: 0.5,
        rootMargin: "0px",
      }
    );

    cards.forEach((card) => observer.observe(card));

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    // Select all cards that should have the effect
    const cardsToAnimate = [
      document.querySelector(
        `.${classes.bigCardContainer} .${classes.cardContainer}:first-child`
      ),
      document.querySelector(
        `.${classes.smallCardContainer} .${classes.cardContainer}.blueCard`
      ),
      ...document.querySelectorAll(`.${classes.cardContainer}.regularCard`), // Add regularCards
    ];

    cardsToAnimate.forEach((card) => {
      if (!card) return;

      card.classList.add("glass-effect");

      // Set initial top-right position for the glass effect
      const setInitialPosition = () => {
        const rect = card.getBoundingClientRect();
        const topRightX = rect.width - 150; // Adjust this value to position from right edge
        const topRightY = 0; // Top position

        card.style.setProperty("--before-left", `${topRightX}px`);
        card.style.setProperty("--before-top", `${topRightY}px`);
        card.style.setProperty("--before-opacity", "0.8");
      };

      // Call it initially
      setInitialPosition();

      let lastX = null;
      let lastY = null;

      const handleMouseMove = (e) => {
        const rect = card.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        lastX = x;
        lastY = y;

        card.style.setProperty("--before-left", `${x - 75}px`);
        card.style.setProperty("--before-top", `${y - 75}px`);
      };

      const handleMouseEnter = () => {
        if (lastX !== null && lastY !== null) {
          card.style.setProperty("--before-left", `${lastX - 75}px`);
          card.style.setProperty("--before-top", `${lastY - 75}px`);
        }
        card.style.setProperty("--before-opacity", "0.8");
      };

      const handleMouseLeave = () => {
        card.style.setProperty("--before-opacity", "0.8");
      };

      // Store handlers on the card element for cleanup
      card.handlers = {
        mousemove: handleMouseMove,
        mouseenter: handleMouseEnter,
        mouseleave: handleMouseLeave,
        resize: setInitialPosition,
      };

      // Add event listeners
      card.addEventListener("mousemove", handleMouseMove);
      card.addEventListener("mouseenter", handleMouseEnter);
      card.addEventListener("mouseleave", handleMouseLeave);
      window.addEventListener("resize", setInitialPosition);
    });

    // Cleanup
    return () => {
      cardsToAnimate.forEach((card) => {
        if (!card) return;

        const handlers = card.handlers;
        if (handlers) {
          card.removeEventListener("mousemove", handlers.mousemove);
          card.removeEventListener("mouseenter", handlers.mouseenter);
          card.removeEventListener("mouseleave", handlers.mouseleave);
          window.removeEventListener("resize", handlers.resize);
        }
      });
    };
  }, [classes]);

  return (
    <main>
      <SEO
        title={
          "AI CRM: Automate Business With Best AI Powered CRM Tool | Pepper Cloud"
        }
        pathname={
          "https://peppercloud.com/sales-crm-features/ai-crm-tool-for-small-business"
        }
        keywords="Best AI-powered CRM, Best AI CRM, crm ai use cases, ai powered crm, ai in crm tools, Best AI-powered sales CRM, ai crm"
        description={
          " Pepper Cloud is the Best AI CRM Tool built for small businesses. Boost customer engagement, efficiency, and business growth with features like AI Chatbot, Automation, and more."
        }
        ogData={{
          "og:image": [
            "/meta/og/1X1/home.png",
            "/meta/og/1024X512/home.png",
            "/meta/og/1200X630/home.png",
          ],
        }}
        jsonLd={[
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Pepper Cloud",
            legalName: "Pepper Cloud PTE LTD",
            url: "https://peppercloud.com/",
            logo: "https://peppercloud.com/icons/logo.png",
            foundingDate: "2017",
            address: [
              {
                "@type": "PostalAddress",
                streetAddress: "#04-01/02",
                addressLocality: "Blk 79 Ayer Rajah Crescent",
                addressRegion: "SG",
                postalCode: "139955",
                addressCountry: "Singapore",
              },
              {
                "@type": "PostalAddress",
                streetAddress:
                  "2nd Floor, Salarpuria Tower 1, 7th Block Koramangala",
                addressLocality: "Bengaluru",
                addressRegion: "KA",
                postalCode: "560095",
                addressCountry: "India",
              },
            ],
            contactPoint: {
              "@type": "ContactPoint",
              contactType: "customer support",
              telephone: "+6566562616",
              email: ["sales@peppercloud.com", "support@peppercloud.com"],
            },
            sameAs: [
              "https://twitter.com/peppercloudpte",
              "https://www.linkedin.com/company/peppercloud/",
              "https://www.facebook.com/PepperCloudPteLtd",
              "https://www.instagram.com/pepper.cloud/",
            ],
          },
          {
            "@context": "https://schema.org/",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "What is AI CRM?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "AI CRM combines traditional customer relationship management software with artificial intelligence capabilities to automate tasks, provide insights, and enhance customer interactions.",
                },
              },
              {
                "@type": "Question",
                name: "How does AI improve CRM functionality?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "AI enhances CRM by automating routine tasks, providing predictive analytics, offering personalised recommendations, and improving customer support through chatbots and intelligent assistants.",
                },
              },
              {
                "@type": "Question",
                name: "How does AI CRM enhance customer support?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "AI CRM can provide 24/7 automated chat support, answer FAQs instantly, and seamlessly hand off complex queries to human agents when necessary.",
                },
              },
              {
                "@type": "Question",
                name: "What channels can AssistAI chatbot support?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "AssistAI chatbot can automate and personalise interactions across WhatsApp, Telegram, Facebook, and Instagram.",
                },
              },
              {
                "@type": "Question",
                name: "How does AI CRM assist in task automation?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "AI CRM can automate repetitive tasks like creating and updating records, managing notes and call logs, and searching for records using simple commands in the AssistAI panel.",
                },
              },
              {
                "@type": "Question",
                name: "Can AI CRM help with sales forecasting?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, AI CRM uses predictive analytics to forecast trends and outcomes, helping businesses make informed decisions and strategise effectively.",
                },
              },
              {
                "@type": "Question",
                name: "How does AI CRM personalise customer interactions?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "AI-powered insights enable tailored communication strategies, allowing businesses to adjust tone, rephrase messages, and deliver personalised content to each customer segment.",
                },
              },
              {
                "@type": "Question",
                name: "Is AI CRM suitable for small and medium-sized businesses?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, many AI CRMs, including Pepper Cloud, offer scalable solutions that can adapt to the evolving needs of small and medium-sized businesses.",
                },
              },
              {
                "@type": "Question",
                name: "How to choose the best AI CRM software for your SMB?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "To choose the best AI CRM, clearly define your goals and assess how the system's AI capabilities align with your business needs. Prioritise user-friendliness, integration potential, scalability, and available support to ensure successful implementation and long-term value for your growing business.",
                },
              },
              {
                "@type": "Question",
                name: "What are the best 5 AI CRM?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Pepper Cloud AI CRM stands out with its advanced AI technology, AssistAI. Featuring sales conversation summarisation, intelligent communication enhancement, a conversational chatbot, 1-click customer responses, a dynamic knowledge base, and more, it is a top choice for growing businesses. Plus, its advanced sales features like auto-lead capture, sales forecasting, workflow automation, WhatsApp integration, voice and video calling, analytics, and more, make it a powerful AI-powered sales CRM.  Other top AI CRMs include HubSpot (known for content generation and lead scoring), Salesforce (offering Einstein AI for sales predictions), Pipedrive (focused on pipeline management), and Zoho CRM (for insights and automation).",
                },
              },
            ],
          },
        ]}
      />

      <section className={classes.heroSectionOuter}>
        <div className="container">
          <div className={classes.innerHeroSection}>
            <motion.h1
              initial={{ opacity: 1, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="font-wix-semibold font-84"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <LazyImage source={aiIcon} alt={"AssistAi - Ai crm"} />
              AssistAI
            </motion.h1>
            <motion.p
              initial={{ opacity: 1, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="font-20 font-wix-medium"
            >
              Harness the power of Pepper Cloud's AssistAI to simplify
              conversations, automate tasks, streamline workflows, and more.
              Save time, improve productivity, and make smarter sales decisions
              with the best AI CRM for your sales workflow.
            </motion.p>
            <motion.div
              initial={{ opacity: 1, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="buttonContaienr mt30"
            >
              <Link
                className="primaryButton font-20 font-wix-medium font-white"
                to="/contact?utm_source=AI+CRM&utm_medium=Try+AssistAI"
                // target="_blank"
              >
                Try AssistAI
              </Link>
            </motion.div>
          </div>
          <motion.div
            className={classes.homeSectionVideo}
            initial={{ opacity: 1, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <PlayableVideoComponent
              alt="Best sales CRM software"
              source={aiPageHeroSection}
            />
            {/* <video
              src={aiPageHeroSection}
              autoPlay={true}
              controls={true}
              playsInline
              controlsList="nodownload"
              width={"100%"}
            /> */}
          </motion.div>
        </div>
      </section>
      <section className={classes.pageBackground}>
        <section className={classes.partnerSection}>
          <div className="container">
            <div className={classes.partnerContainer}>
              <motion.h2
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="font-wix-semibold font-24 color-teal3"
              >
                Best AI-powered sales CRM - Trusted by businesses globally
              </motion.h2>
              <motion.div
                className={classes.partnerIcons}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
              >
                <img
                  src={partnerIcon1}
                  alt="caffeine solutions - Pepper Cloud Customer"
                />
                <img src={partnerIcon2} alt="cuscapi - Pepper Cloud Customer" />
                <img
                  src={partnerIcon3}
                  alt="TechData - Pepper Cloud Customer"
                />
                <img src={partnerIcon8} alt="sis - Pepper Cloud Customer" />
                <img
                  src={partnerIcon5}
                  alt="mustard seed systems corporation - Pepper Cloud Customer"
                />
                <img
                  src={partnerIcon6}
                  alt="Singapore Maritime Foundation - Pepper Cloud Customer"
                />
                <img
                  src={partnerIcon7}
                  alt="urban moods - Pepper Cloud Customer"
                />
              </motion.div>
            </div>
          </div>
        </section>

        <section className={classes.cardsSection}>
          <div className="container">
            <div className="centerHeader">
              <p className="font-18 font-wix-semibold font-white">Features</p>
              <motion.h2
                initial={{ opacity: 1, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="font-wix-medium font-56 gradientHeading"
              >
                Accelerate sales success with advanced AssistAI{" "}
                <img src={glareIcon} alt={"AI automation"} /> capabilities
              </motion.h2>
              <motion.p
                initial={{ opacity: 1, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="font-wix-regular font-18 color-black5 mt10"
              >
                Experience the power of AssistAI, the intelligent generative AI
                within Pepper Cloud CRM, designed to streamline your
                communication and enhance customer engagement. From handling
                customer queries to automating workflows and simplifying task
                management, AssistAI empowers your team to deliver efficient
                support, close deals faster, and scale your business.
              </motion.p>
            </div>
            <ul id="cards">
              {capabilities.map((data, index) => (
                <li className="card" id={`card-${index + 1}`}>
                  <div
                    className="card-content"
                    style={{ background: data.background }}
                  >
                    <div className="cardContentData">
                      <h3 className="cardHeading font-wix-medium">
                        {data.heading}
                      </h3>
                      <p className="cardDesciption">{data.desc}</p>
                      <div className="cardList">
                        {data.points.map((point, j) => (
                          <div className="listItem">
                            <LazyImage
                              source={point.icon}
                              alt={"pointerIcon"}
                            />
                            <p>{point.text}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="cardImg">
                      <LottieIcon
                        autoplay
                        loop
                        animationData={JSON.parse(JSON.stringify(data.video))}
                        hover={false}
                        play
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section>
          <div className="container">
            <div className={classes.growthSection}>
              <LazyImage source={glareIcon} alt={"AI automation"} />
              <motion.h2
                initial={{ opacity: 1, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="font-wix-semibold font-56 gradientHeading"
              >
                Pepper Cloud AI CRM crafted for your growth
              </motion.h2>
            </div>
            <div className={classes.bigCardContainer}>
              <motion.div
                // onMouseEnter={() => !isMobile && setIsHovering1(true)}
                // onMouseLeave={() => !isMobile && setIsHovering1(false)}
                initial={{ opacity: 1, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className={clsx(classes.cardContainer, "purpleCard")}
              >
                <h3 className="font-wix-medium font-32 font-white">
                  Intelligent chatflow <br /> automation
                </h3>
                <p className="font-wix-medium font-18 color-black7">
                  Automate lead conversion, trigger actions, and respond
                  instantly with AI-powered CRM. Let smart chatflow handle your
                  routine tasks while you focus on what matters – closing deals.
                </p>
                <LottieIcon
                  className={classes.absoluteImage}
                  autoplay
                  loop
                  animationData={JSON.parse(JSON.stringify(aiAnimationJson1))}
                  hover={false}
                  play
                />
                {/* <img
                  src={aiAnimationJson3Gif}
                  className={classes.absoluteImage}
                /> */}
              </motion.div>
              <motion.div
                initial={{ opacity: 1, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className={clsx(classes.cardContainer, "regularCard")}
                // onMouseEnter={() => !isMobile && setIsHovering2(true)}
                // onMouseLeave={() => !isMobile && setIsHovering2(false)}
              >
                <h3 className="font-wix-medium font-36 font-white">
                  Customise every <br /> detail your way
                </h3>
                <p className="font-wix-medium font-18 color-black7">
                  Personalise chatbot appearance to reflect your brand identity.
                  Train AssistAI with a customisable knowledge base for accurate
                  responses. Create adaptable chatflows designed to meet your
                  needs.
                </p>
                <LottieIcon
                  className={classes.absoluteImage}
                  autoplay
                  loop
                  animationData={JSON.parse(JSON.stringify(aiAnimationJson2))}
                  hover={false}
                  play
                />
                {/* <img
                  src={aiAnimationJson4Gif}
                  className={classes.absoluteImage}
                /> */}
              </motion.div>
            </div>
            <div className={classes.smallCardContainer}>
              <motion.div
                initial={{ opacity: 1, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className={clsx(classes.cardContainer, "regularCard")}
                // onMouseEnter={() => !isMobile && setIsHovering3(true)}
                // onMouseLeave={() => !isMobile && setIsHovering3(false)}
              >
                <h3 className="font-wix-medium font-36 font-white">
                  Omnichannel presence
                </h3>
                <p className="font-wix-medium font-18 color-black7">
                  Stay engaged with your customers across various communication
                  channels using AssistAI. Connect and support seamlessly on
                  WhatsApp, Facebook Messenger, Instagram, and more.
                </p>
                <LottieIcon
                  className={classes.absoluteImage}
                  autoplay
                  loop
                  animationData={JSON.parse(JSON.stringify(aiAnimationJson3))}
                  hover={false}
                  play
                />
                {/* <img
                  src={aiAnimationJson5Gif}
                  className={classes.absoluteImage}
                /> */}
              </motion.div>
              <motion.div
                initial={{ opacity: 1, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className={clsx(classes.cardContainer, "blueCard")}
                // onMouseEnter={() => !isMobile && setIsHovering4(true)}
                // onMouseLeave={() => !isMobile && setIsHovering4(false)}
              >
                <h3 className="font-wix-medium font-36 font-white">
                  Faster chat-to-client conversions
                </h3>
                <p className="font-wix-medium font-18 color-black7">
                  Turn every conversation into customer at record speed with
                  complete process automation using AssistAI. Enhance
                  engagement, lower churn rate, and boost conversions
                  effortlessly.
                </p>
                <LottieIcon
                  className={classes.absoluteImage}
                  autoplay
                  loop
                  animationData={JSON.parse(JSON.stringify(aiAnimationJson4))}
                  hover={false}
                  play
                />
                {/* <img
                  src={aiAnimationJson6Gif}
                  className={classes.absoluteImage}
                /> */}
              </motion.div>
              <motion.div
                initial={{ opacity: 1, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className={clsx(classes.cardContainer, "regularCard")}
                // onMouseEnter={() => !isMobile && setIsHovering5(true)}
                // onMouseLeave={() => !isMobile && setIsHovering5(false)}
              >
                <h3 className="font-wix-medium font-36 font-white">
                  Smart AI-driven insights
                </h3>
                <p className="font-wix-medium font-18 color-black7">
                  Analyse data and generate actionable insights with the power
                  of AssistAI. Gain real-time understanding of customer
                  behaviour, preferences, and patterns to effectively win every
                  interaction.
                </p>
                <LottieIcon
                  className={classes.absoluteImage}
                  autoplay
                  loop
                  animationData={JSON.parse(JSON.stringify(aiAnimationJson5))}
                  hover={false}
                  play
                />
                {/* <img
                  src={aiAnimationJson7Gif}
                  className={classes.absoluteImage}
                /> */}
              </motion.div>
            </div>
          </div>
        </section>
      </section>

      <section className={classes.benifitSection}>
        <div className="container">
          <div className={classes.integrationHeader}>
            <div className={classes.integrationLeft}>
              <motion.h2
                initial={{ opacity: 1, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="font-wix-semibold font-56 font-white"
              >
                Drive team excellence with AI CRM system
              </motion.h2>
            </div>
            <div className={classes.integrationRight}>
              <motion.p
                initial={{ opacity: 1, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="font-wix-regular font-18 color-black5"
              >
                Experience the generative power of AI CRM system across various
                industries and teams. Drive results, save time, and boost
                productivity across marketing, sales, and support teams
                effortlessly.
              </motion.p>
            </div>
          </div>
          <div className={classes.excellenceSystem}>
            <div className={classes.excellenceSystemTabs}>
              <div
                onClick={() => setActiveTab(0)}
                className={clsx(
                  classes.excellenceSystemTab,
                  activeTab === 0 && "active",
                  "color-black5",
                  "font-20"
                )}
              >
                Marketing optimisation
              </div>
              <div
                onClick={() => setActiveTab(1)}
                className={clsx(
                  classes.excellenceSystemTab,
                  activeTab === 1 && "active",
                  "color-black5",
                  "font-20"
                )}
              >
                Sales management
              </div>
              <div
                onClick={() => setActiveTab(2)}
                className={clsx(
                  classes.excellenceSystemTab,
                  activeTab === 2 && "active",
                  "color-black5",
                  "font-20"
                )}
              >
                Customer support
              </div>
            </div>
            {excellencePoints.map(
              (excellencePoint, index) =>
                index === activeTab && (
                  <motion.div className={classes.excellenceSystemContainer}>
                    <p className="font-18 color-black7 font-wix-medium">
                      {excellencePoint.heading}
                    </p>
                    <div className={classes.excellenceSystemCards}>
                      {excellencePoint.cards.map((card, i) => (
                        <motion.div
                          whileInView={{ opacity: [1, 1], x: [-30, 0] }}
                          transition={{ duration: 1, delay: i * 0.1 }}
                          className={classes.excellenceSystemCard}
                        >
                          <div className="imageContainer">
                            <LazyImage
                              height={"60px"}
                              width={"60px"}
                              source={card.icon}
                              alt={"sparkIcon"}
                            />
                          </div>
                          <h3 className="heading font-22 font-white font-wix-semibold">
                            {card.title}
                          </h3>
                          <p className="description font-18 font-wix-regular">
                            {card.desc}
                          </p>
                        </motion.div>
                      ))}
                    </div>
                  </motion.div>
                )
            )}
          </div>
          <div className={classes.absoluteCard}>
            <div className="leftSection">
              <motion.h2
                initial={{ opacity: 1, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="font-wix-medium font-40 font-white"
              >
                AssistAI: Your trusted <br /> AI partner
              </motion.h2>
              <motion.p
                initial={{ opacity: 1, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="font-wix-regular font-18 color-teal3"
              >
                Built on enterprise-grade security with end-to-end encryption
                and advanced authentication, AssistAI brings you the perfect
                balance of power and privacy. Your data stays protected around
                the clock with automated backups, while our PDPA-compliant
                platform streamlines consent management and permissions—all
                managed automatically.
              </motion.p>
            </div>
            <div className="rightSection">
              <LazyImage source={absoluteImage} />
            </div>
          </div>
        </div>
      </section>

      <FaqSection
        backgroundColor={"#FFF9F0"}
        padding={"200px  0 0 0"}
        heading={"Understanding AI-powered CRM: Frequently asked questions"}
        faqs={faqs}
      />

      <section className={classes.commonPageFooterSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="font-wix-medium font-56 font-white"
            >
              Streamline sales success with Pepper Cloud AI CRM
            </motion.h2>
            <Link
              className="primaryButton font-20 font-wix-medium font-white"
              to="/contact?utm_source=AI+CRM&utm_medium=Get+started"
            >
              Get started
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Ai;
